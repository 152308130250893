/* General Modal Backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* More pronounced backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is on top of other content */
}

/* General Modal Container */
.modal-container {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2); /* Elevated shadow */
  padding: 25px;
  max-width: 600px; /* Increased width for more space */
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  z-index: 1001; /* Ensure it is above the backdrop */
  animation: fadeIn 0.3s ease-in-out; /* Smooth animation */
}

/* Success Modal Specific Styles */
.modal-container.success {
  background: linear-gradient(145deg, #d4f5de, #f0fffa); /* Modern gradient */
  border: 2px solid #38a169; /* Green border */
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.close-btn {
  font-size: 1.5rem;
  cursor: pointer;
  color: #333; /* Close button color */
  transition: transform 0.2s;
}

.close-btn:hover {
  transform: scale(1.2);
}

/* Modal Body */
.modal-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #2f855a; /* Deep green for success */
  margin-bottom: 10px;
}

.popup-response {
  background-color: #e6f4ea; /* Subtle background for content */
  padding: 15px;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.popup-response p {
  margin: 0.5rem 0;
}

.response-value {
  font-weight: bold;
  color: #2c7a7b; /* Highlight important details */
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .modal-container {
    max-width: 95%;
    padding: 20px;
  }

  .modal-title {
    font-size: 1.5rem;
  }

  .popup-response {
    font-size: 0.9rem;
  }
}
