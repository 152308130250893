/* Modernized TirumalaDC.css */

body {
  font-family: "Roboto", sans-serif;
  background-color: #fafaf7;
  color: #333;
}

.tirumala-dc-form {
  padding: 25px;
  border-radius: 12px;
  background: linear-gradient(145deg, #ffffff, #e4e4e7);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1), -3px -3px 10px rgba(255, 255, 255, 0.6);
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 85%; /* Made form wider */
  max-width: 96%; /* Max width for larger screens */
  margin: 20px auto;
}

.tirumala-dc-form h1 {
  font-size: 1.8rem;
  font-weight: 600;
  text-align: center;
  color: #333;
  margin-bottom: 15px;
}

.tirumala-dc-form label {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 8px;
  display: block;
}

.tirumala-dc-form input,
.tirumala-dc-form select {
  padding: 12px 15px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  box-shadow: inset 2px 2px 6px rgba(0, 0, 0, 0.05), inset -2px -2px 6px rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.tirumala-dc-form input:focus,
.tirumala-dc-form select:focus {
  outline: none;
  border-color: #6366f1;
  box-shadow: 0 0 6px rgba(99, 102, 241, 0.4);
}

.tirumala-dc-form button {
  background-color: #4f46e5;
  margin-left: 5px;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.tirumala-dc-form button:hover {
  background-color: #4338ca;
  transform: translateY(-2px);
}

.tirumala-dc-form button:active {
  transform: translateY(1px);
}

.error {
  color: #f87171;
  font-size: 0.9rem;
  margin-top: 5px;
}

.success-message {
  color: #10b981;
  font-size: 1rem;
  text-align: center;
}

.image-preview {
  margin-top: 15px;
  text-align: center;
}

.image-preview img {
  max-width: 10rem;
  height: 9rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tirumala-page-image {
  height: 3rem; /* Reduced logo height */
  width: 14rem; /* Reduced logo width */
  display: block;
  margin: 0 auto; /* Centered logo */
}

.grey-button-next {
  background-color: #9ca3af !important;
  color: white !important;
}


.loan-amount-section{
  display: grid;
place-items: center;
grid-template-columns: 1fr;
gap: 0.5rem;
}

.delete-loan{
  background-color: #f87171 !important;
  color: white;
}
.loan-amount-section-first{
display: grid;
place-items: center;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
gap: 0.3rem;
}
.loan-amount-section-second{
  justify-self: start;
}
.total-loan-amount {
  font-weight: 600;
  color: #10b981;
  font-size: 1.2rem;
}

.delete-loan {
  background-color: #f87171;
  color: white;
  border-radius: 6px;
  padding: 5px 10px;
}

@media (max-width: 600px) {
  .tirumala-dc-form {
    width: 95%; /* Adjust width for smaller screens */
    padding: 15px;
  }

  .tirumala-dc-form button {
    font-size: 0.9rem;
    padding: 10px;
  }

  .tirumala-page-image {
    height: 1.5rem; /* Reduce logo size further on mobile */
    width: 5rem;
  }
}
