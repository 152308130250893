.form-container {
    background-color: #f9f6f1;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 50px auto;
    font-family: Arial, sans-serif;
    text-align: center; /* Center align text */
  }
  
  .logo{
    max-width: 100%;
    height: auto;
    margin-bottom: 20px; 
  }
  
  .form-title {
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    text-align: left; /* Align labels and inputs to the left */
  }
  
  .input-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .input-group input,
  .input-group select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    background-color: #f0c040;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #e0b030;
  }
  .add-loan-button{
   background-color: #f0c040;
    font-size: 1rem;
    color: black;
    border: none;
    outline: none;
    padding: 0.5rem;
    width: 100%;
    border-radius: 5px;
    margin-top: 6px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  
  @media (max-width: 480px) {
    .form-container {
      padding: 15px;
    }
  
    .submit-button {
      font-size: 14px;
    }
  }
  