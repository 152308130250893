/* General Modal Backdrop */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* More opaque backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Error Modal Specific Styles */
.modal-container.error {
  background: linear-gradient(145deg, #ffe5e5, #fdf7f7); /* Subtle gradient background */
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3); /* Elevated shadow */
  max-width: 500px; /* Increased width for a modern look */
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 8px solid #e63946; /* Bold red border */
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.close-btn {
  font-size: 1.8rem;
  cursor: pointer;
  color: #444;
  transition: transform 0.2s;
}

.close-btn:hover {
  transform: scale(1.2); /* Interactive hover effect */
  color: #e63946;
}

/* Modal Body */
.modal-body {
  margin-top: 15px;
  color: #e63946; /* Deep red for error messages */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1rem; /* Add spacing between elements */
  width: 100%;
}

.error-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #e63946; /* Accent error color */
}

.error-response {
  background-color: #ffecec; /* Soft red for error details */
  padding: 15px;
  border-radius: 8px;
  font-size: 1rem;
  color: #444;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.error-details {
  font-size: 1.2rem;
  color: #e63946;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 600px) {
  .modal-container.error {
    padding: 20px;
    max-width: 95%;
  }

  .error-title {
    font-size: 1.5rem;
  }

  .error-response {
    font-size: 0.9rem;
  }
}
