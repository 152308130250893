/* Header.css */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f9f6f1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    font-family: Arial, sans-serif;
  }
  
  .header-logo {
    max-height: 50px;
  }
  
  .header-nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .header-nav li {
    margin: 0 15px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
  }
  
  .header-nav li:hover {
    color: #f0c040;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .header-nav ul {
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
    }
  
    .header-nav li {
      margin: 10px 0;
    }

    .header-logo {
      width: 100%;
    }

  }
  