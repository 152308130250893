/* ResultDisplay.css */
.result-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.result-container {
  background-color: #fff9e7;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.result-logo {
  max-width: 100%; /* Make the logo responsive */
  height: auto;
  margin-bottom: 20px; /* Space between logo and title */
}

.result-title {
  margin-bottom: 20px;
  color: #333;
}

.result-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0 20px;
}

.result-group-premium {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 20px;
}

.result-group-premium label {
  font-weight: bold;
  color: #555;
}

.result-group label {
  font-weight: bold;
  color: #555;
}

.result-value {
  font-size: 1.1em;
  color: #333;
}

.result-gst {
  font-size: 0.8em;
  color: #999;
  text-align: right;
  padding-right: 20px;
}

.result-button {
  background-color: #ffc107;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  font-size: 1em;
  margin-top: 20px;
  width: 100%;
}

.result-button:hover {
  background-color: #e0a800;
}

.additional-text {
  max-width: 400px;
  width: 100%;
  text-align: center;
  font-size: 0.9em;
  color: #555;
  padding: 0 20px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .result-container,
  .additional-text {
    padding: 20px;
  }

  .result-group,
  .result-group-premium {
    padding: 0 10px;
  }

  .result-gst {
    padding-right: 10px;
  }
}
