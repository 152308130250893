/* Section.css */

.App {
  font-family: Arial, sans-serif;
}

.section {
  padding: 100px;
  border-bottom: 1px solid #ccc;
  border: none;
}
#how-to-use{
  background-color: #f9f6f1;
}
#contact{
  background-color: #f9f6f1;
}
.section:last-of-type {
  border-bottom: none;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-image {
  width: 200px;
  height: 200px;
}

.text {
  width: 55%;
}

.contact-text {
  width: 100%;
  text-align: center;
}

.contact-section .text {
  text-align: center;
}

.contact-number {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
  padding: 10px 20px;
  border: 2px solid #FF00FF;
  border-radius: 30px;
  background-color: white;
  color: #FF00FF;
  cursor: pointer;
}

.footer {
  background-color: #000;
  /* color: #fff; */
  display: flex;
  justify-content: space-around;
  padding: 20px;
  height: 200px;
}

.footer-column {
  flex: 1;
  padding: 0 20px;
}

.footer-column h3 {
  font-size: 1.5em;
  font-weight: bold;
  color: white;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}


.footer-column ul li a{
  margin-bottom: 10px;
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.social-icons {
  display: flex;
}

.social-icons a {
  margin-right: 10px;
  text-decoration: none;
}

.social-icons img {
  width: 30px;
  height: 30px;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .section-image, .text {
    width: 100%;
  }

  .section-image {
    margin: 20px 0;
  }

  .section {
    padding: 30px;
    border-bottom: 1px solid #ccc;
  }

  .footer {
    flex-direction: column;
    height: auto;
  }

  .footer-column {
    padding: 20px 0;
  }
}
