/* Base styles for login page */
.login-page {
  font-family: Arial, sans-serif;
  background-color: #e9eaeb;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100vh;
}
.login-backdrop{
  font-family: Arial, sans-serif;
  background-color: #e0b030;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100vh;
}

.login-page-image {
  height: 5rem;
  width: 20rem;
}

/* Container for the login form */
.login-container {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
}

/* Title style */
.login-title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  text-align: center;
}

/* Input fields */
.login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  margin-top: 10px;
}

/* Input focus style */
.login-input:focus {
  border-color: #f0c040;
  outline: none;
}

/* Button style */
.login-button {
  width: 100%;
  padding: 10px;
  background-color: #f0c040;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.resend-button{
  margin-top: 0.5rem;
}

/* Button hover and active states */
.login-button:hover {
  background-color: #e0b736;
}

.login-button:active {
  background-color: #d0a92a;
}
.login-button-disabled{
  background-color: gray;
  color: white;
}

/* Error message style */
.login-error {
  color: red;
  margin-bottom: 20px;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 600px) {
  .login-page {
    padding: 1rem;
  }

  .login-page-image {
    height: 3rem;
    width: 15rem;
  }

  .login-container {
    padding: 20px;
    width: 100%;
    max-width: 90%;
  }

  .login-title {
    font-size: 20px;
  }

  .login-input {
    font-size: 14px;
  }

  .login-button {
    font-size: 14px;
  }
}
